/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '~swiper/swiper-bundle';

@font-face {
  font-family: "ConneqtRegular";
  src: url('./assets/webFonts/ConneqtRegular/font.woff2') format('woff2'), url('./assets/webFonts/ConneqtRegular/font.woff') format('woff');
}
@font-face {
  font-family: "ConneqtBlack";
  src: url('./assets/webFonts/ConneqtBlack/font.woff2') format('woff2'), url('./assets/webFonts/ConneqtBlack/font.woff') format('woff');
}
@font-face {
  font-family: "ConneqtBold";
  src: url('./assets/webFonts/ConneqtBold/font.woff2') format('woff2'), url('./assets/webFonts/ConneqtBold/font.woff') format('woff');
}

@font-face {
  font-family: 'Saira Condensed';
  src: url('./assets/webFonts/SairaCondensed/SairaCondensed-Regular.woff2') format('woff2'),
      url('./assets/webFonts/SairaCondensed/SairaCondensed-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Saira Condensed';
  src: url('./assets/webFonts/SairaCondensed/SairaCondensed-Bold.woff2') format('woff2'),
      url('./assets/webFonts/SairaCondensed/SairaCondensed-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Saira Condensed';
  src: url('./assets/webFonts/SairaCondensed/SairaCondensed-Thin.woff2') format('woff2'),
      url('./assets/webFonts/SairaCondensed/SairaCondensed-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Saira Condensed';
  src: url('./assets/webFonts/SairaCondensed/SairaCondensed-Medium.woff2') format('woff2'),
      url('./assets/webFonts/SairaCondensed/SairaCondensed-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Saira Condensed';
  src: url('./assets/webFonts/SairaCondensed/SairaCondensed-ExtraLight.woff2') format('woff2'),
      url('./assets/webFonts/SairaCondensed/SairaCondensed-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Saira Condensed';
  src: url('./assets/webFonts/SairaCondensed/SairaCondensed-Black.woff2') format('woff2'),
      url('./assets/webFonts/SairaCondensed/SairaCondensed-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Saira Condensed';
  src: url('./assets/webFonts/SairaCondensed/SairaCondensed-Light.woff2') format('woff2'),
      url('./assets/webFonts/SairaCondensed/SairaCondensed-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Saira Condensed';
  src: url('./assets/webFonts/SairaCondensed/SairaCondensed-SemiBold.woff2') format('woff2'),
      url('./assets/webFonts/SairaCondensed/SairaCondensed-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Saira Condensed';
  src: url('./assets/webFonts/SairaCondensed/SairaCondensed-ExtraBold.woff2') format('woff2'),
      url('./assets/webFonts/SairaCondensed/SairaCondensed-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



:root {
  --swiper-theme-color: #7BBFD3;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 20px !important;
}